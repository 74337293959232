/* eslint-disable indent */
import Styled from 'styled-components/macro';
import Media from '@uizard-io/styled-media-query';

import { Theme } from '../../theme';

import {
  SpacerConfigurationType,
  SpacerProps,
} from './index';

const spacerProps = (theme: Theme, configuration: SpacerConfigurationType) => {
  const spacing = configuration.spacing ? theme.spacing[configuration.spacing] : '0rem';
  const axis = configuration.horizontal ? 'width' : 'height';

  return `
    ${axis}: ${spacing};
    min-${axis}: ${spacing};
  `;
};

export const Spacer = Styled.div<SpacerProps>`
  overflow: hidden;

  ${Media.lessThan('medium')`
    ${(props: any) => spacerProps(props.theme, {
      ...(props.small || {}),
    })}
  `}

  ${Media.greaterThan('medium')`
    ${(props: any) => spacerProps(props.theme, {
      ...(props.small || {}),
      ...(props.medium || {}),
    })}
  `}

  ${Media.greaterThan('large')`
    ${(props: any) => spacerProps(props.theme, {
      ...(props.small || {}),
      ...(props.medium || {}),
      ...(props.large || {}),
    })}
  `}

  ${Media.greaterThan('huge')`
    ${(props: any) => spacerProps(props.theme, {
      ...(props.small || {}),
      ...(props.medium || {}),
      ...(props.large || {}),
      ...(props.huge || {}),
    })}
  `}
`;
