import Styled, { keyframes } from 'styled-components/macro';
import { transparentize } from 'polished';

export interface StyledSpinnerProps {
  size?: number;
  inline?: boolean;
}

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const StyledSpinner = Styled.div<StyledSpinnerProps>`
  display: ${props => (props.inline ? 'inline-block' : 'block')};
  height: ${props => props.size || 1}rem;
  width: ${props => props.size || 1}rem;
  line-height: 1;
  font-size: ${props => props.size || 1}rem;
  position: relative;
  text-indent: -9999em;
  
  border-top: 0.1em solid ${props => transparentize(0.8, props.theme.colors.black)};
  border-right: 0.1em solid ${props => transparentize(0.8, props.theme.colors.black)};
  border-bottom: 0.1em solid ${props => transparentize(0.8, props.theme.colors.black)};
  border-left: 0.1em solid ${props => transparentize(0, props.theme.colors.black)};
  transform: translateZ(0);
  animation: ${spin} 1.1s infinite linear;

  &, &:after {
    border-radius: 50%;
    width: 1em;
    height: 1em;
  }
`;
