import React from 'react';

export interface IconProps {
  liga: string;
  size: number;
}

const Icon: React.FC<IconProps> = ({ size, liga }) => (
  <i
    style={{ fontSize: `${size}rem`, color: 'inherit' }}
    className="uizard-icons"
  >
    {liga}
  </i>
);

export default Icon;
