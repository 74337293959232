import React from 'react';

import { StyledSpinner } from './styles';

export interface SpinnerProps {
  size?: number;
  inline?: boolean;
}

const Loader: React.FC<SpinnerProps> = React.memo(({ size = 2, inline }) => (
  <StyledSpinner size={size} inline={inline} />
));

export default Loader;
