const BASE_URL = process.env.GATSBY_API_URL;

export const useApiPaths = () => ({
  getWebsiteThumbnailUrl: (url: string) => (
    `${BASE_URL}/public/website-thumbnail?url=${encodeURIComponent(url)}`
  ),
  getStyleExtractionUrl: (token: string) => (
    `${BASE_URL}/public/style-extractions/${token}`
  ),
  getCreateStyleExtractionUrl: () => (
    `${BASE_URL}/public/style-extractions`
  )
});
