import React from 'react';
import { Theme } from '../../theme';

import { Spacer as StyledSpacer } from './styles';

export type SpacerConfigurationType = {
  horizontal?: boolean;
  spacing?: keyof Theme['spacing'];
};

export interface SpacerProps {
  small?: SpacerConfigurationType;
  medium?: SpacerConfigurationType;
  large?: SpacerConfigurationType;
  huge?: SpacerConfigurationType;
}

const Spacer: React.FC<SpacerProps> = ({ small, medium, large, huge }) => (
  <StyledSpacer small={small} medium={medium} large={large} huge={huge} />
);

export default Spacer;
